import { AxiosResponse } from "axios";
import { http } from "../HttpInstance";
import { LSKItem } from "types";

const endpoint = "/api/v1/integrations/ls-k-series/items";

export const getItemBySku = async (locationId: string, sku: string): Promise<AxiosResponse<{ item: LSKItem }>> => {
    return http.instance.get(`${endpoint}/sku?business_location_id=${locationId}&sku=${sku}`);
}

export const getItemsForLocation = async (locationId: string): Promise<AxiosResponse<Array<LSKItem>>> => {
    return http.instance.get(`${endpoint}/sku?business_location_id=${locationId}`);
}

export const getOptionsForReward = async (uuid: string): Promise<AxiosResponse<Array<{
    label: string,
    value: string
}>>> => {
    return http.instance.get(`/api/v1/integrations/ls-k-series/item-options/reward/${uuid}`);
}

export const getOptionsForPromotion = async (uuid: string): Promise<AxiosResponse<Array<{
    label: string,
    value: string
}>>> => {
    return http.instance.get(`/api/v1/integrations/ls-k-series/item-options/promotion/${uuid}`);
}
