import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
    BrowserSession,
    GiftcardSession,
    PortalSession,
    PrepaidSession,
    RewardSession,
    VoucherSession
} from "../modules";

const router = createBrowserRouter([
    {
        path: "/",
        element: <PortalSession/>,
    },
    {
        path: "/portal",
        element: <RewardSession/>,
    },
    {
        path: "/vouchers",
        element: <VoucherSession/>,
    },
    {
        path: "/giftcards",
        element: <GiftcardSession/>,
    },
    {
        path: "/prepaid",
        element: <PrepaidSession/>,
    },
    {
        path: "/browser",
        element: <BrowserSession/>,
    },
]);

const Router = () => <RouterProvider router={router}/>;

export default Router;
